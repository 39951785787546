export function useAuth() {
  const storeStore = useStoreStore()
  const redirectToLogin = (notEmbeddedAction: () => void) => {
    if (useEmbedded().isEmbedded.value) {
      const url = storeStore.store?.url
      if (storeStore.store?.provider === 1) {
        navigateExternalUrl(`${url}/account/login`)
      } else {
        navigateExternalUrl(`${url}/index.php?controller=my-account`)
      }

      return
    }
    notEmbeddedAction()
  }

  return {
    redirectToLogin
  }
}
